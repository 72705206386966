<template>
  <div class="page">
    <top />
    <div style="width: 100%; height: 55px"></div>
    <designer />
    <div class="home">
      <div @click="go('My')">我的主页</div>
      <div>\</div>
      <div style="color: #e66617">充值</div>
    </div>
    <div class="off">
      <div class="offline">
        <div>充值金额</div>
        <div class="money">
          <div
            :class="quicklyActive == 1 ? ' fixed num-active' : ' fixed '"
            @click="quick('100', 1)"
          >
            100
          </div>
          <div
            :class="quicklyActive == 2 ? ' fixed num-active' : ' fixed '"
            @click="quick('500', 2)"
          >
            500
          </div>
          <div
            :class="quicklyActive == 3 ? ' fixed num-active' : ' fixed '"
            @click="quick('1000', 3)"
          >
            1000
          </div>
          <div class="inputMoney">
            <input type="number" placeholder="自定义金额" v-model="money" />
            <div style="margin-left: 10px">元</div>
          </div>
          <div>最低充值{{ lastMoney }}元</div>
        </div>
      </div>
      <div class="offline">
        <div>充值方式</div>
        <div @click="choose()">
          <img src="../../assets/wx1.png" alt="" v-if="zfMe" />
          <img src="../../assets/wx2.png" alt="" v-else />
        </div>
      </div>
      <div class="offline1">
        <div @click="operation">确认充值</div>
        <!-- <div>充值明细</div> -->
      </div>
      <div class="offlin2" v-html="value"></div>
    </div>
    <!-- 充值弹框 -->

    <info />
    <div style="width: 100%; height: 60px; background-color: #f6f6f6"></div>
    <foot />
  </div>
</template>

<script>
import top from "../../components/common/header.vue";
import foot from "../../components/common/footer.vue";
import info from "../../components/common/qrCode.vue";
import designer from "../../components/common/designer.vue";
import {
  balanceBefore,
  balance,
  pay_wechat_png,
  order_pay_polling,
  select_pay_method,
  wechatMobileLogin,
  wxPublicCodeLogin,
} from "../../request/api";
export default {
  components: {
    top,
    foot,
    designer,
    info,
  },
  data() {
    return {
      quicklyActive: "",
      isClose: false,
      money: "", //充值金额
      zfMe: false,
      value: "", //下方提示信息
      lastMoney: "", //最低充值金额
      bounced: false, //充值弹框
      orderInfo: "",
      downloadData: {
        url: "扫码访问的链接地址",
        icon: "随便一张图片的地址也行",
      },
      jspayData: {},
    };
  },
  created() {
    this.getBalanceBefore();
  },
  methods: {
    //获取最低充值金额
    getBalanceBefore() {
      balanceBefore({}).then((res) => {
        this.value = this.showHtml(res.data.rechargeprotocol.value);
        this.lastMoney = res.data.config.value;
      });
    },
    //快速选择
    quick(money, num) {
      this.money = money;
      this.quicklyActive = num;
    },
    go(url) {
      this.$router.push({
        name: url,
      });
    },
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },

    //确认充值
    operation() {
      if (this.money == "" || this.money == null) {
        this.$message.error("请输入金额");
        return;
      }

      if (!this.zfMe) {
        this.$message.error("请选择支付方式");
        return;
      }

      balance({
        money: this.money,
        payment_type: "zxzf_wechat",
      }).then((res) => {
        if (res.code == 200) {
          this.orderInfo = res.data;

          pay_wechat_png({
            unified_number: this.orderInfo.unified_number,
            transaction_type: this.orderInfo.transaction_type,
          }).then((res) => {
            if (res.code == 200) {
              if (typeof res.data == "string") {
                window.location.href = res.data;
              } else {
                this.jspayData = res.data;
                this.wxpay();
              }
              this.getSelect_pay_method();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    //支付接口列表
    getSelect_pay_method() {
      console.log(this.orderInfo.unified_id);
      console.log(this.orderInfo.unified_number);
      select_pay_method({
        unified_id: this.orderInfo.unified_id,
        unified_number: this.orderInfo.unified_number,
        transaction_type: 1,
        pay_mark: "wechat",
        pay_id: 1,
      })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.order_pay();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    wxpay() {
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        this.jspayData,
        function (res) {
          console.log(res);
        },
        function (res) {
          //提示引用的是mint-UI里toast
          console.log(res);
          this.$message.error("已取消支付");
        },
        function (res) {
          console.log(res);
          this.$message.error("支付失败，请重试");
        }
      );
    },
    // //订单支付轮询
    order_pay() {
      var time = setInterval(() => {
        order_pay_polling({
          unified_id: this.orderInfo.unified_id,
          unified_number: this.orderInfo.unified_number,
          transaction_type: 1,
          pay_mark: "wechat",
          pay_id: 1,
        }).then((res) => {
          console.log("222", res);
          if (res.code == 200) {
            this.$message({
              message: "支付成功",
              type: "success",
            });

            clearInterval(time);
            this.$router.push({
              name: "Order",
            });
          }
        });
      }, 2000);
    },
    //选择支付方式
    choose() {
      this.zfMe = !this.zfMe;
      // if (this.zfMe && !localStorage.getItem("iswx")) {
      //   this.show();
      // }
    },
    show() {
      wechatMobileLogin({}).then((res) => {
        console.log(res);
        if (res.code == 200) {
          window.location.href = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getWxCode() {
      if (!this.$route.query.code) {
        return;
      }

      this.wxCode = this.$route.query.code;

      wxPublicCodeLogin({
        code: this.wxCode,
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem("iswx", 1);
          localStorage.setItem("head_pic", res.data.head_pic);
          localStorage.setItem("nickname", res.data.nickname);
          this.$router.replace({
            path: "/recharge",
          });
        }

        if (res.code == 500) {
          localStorage.setItem("iswx", 0);
          this.$message.error(res.msg);
          let path = this.$route.path; //先获取路由路径
          this.$router.push(path);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background: #f6f6f6;

  .home {
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-size: 13px;

    div {
      margin-right: 7px;
    }
  }

  .off {
    margin-top: 15px;
    padding: 0 10px;

    .offline {
      padding: 12px;
      font-size: 12px;
      background-color: #ffffff;
      color: #08243c;
      display: flex;

      div:first-child {
        width: 25%;
      }

      .money {
        margin-left: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .inputMoney {
          display: flex;
          align-items: center;

          input {
            width: 72px;
            height: 28px;
            border: 1px #cdd6de solid;
            text-align: center;
          }
        }

        .fixed {
          width: 75px;
          height: 30px;
          text-align: center;
          line-height: 30px;
          border: 1px #cdd6de solid;
          margin-bottom: 9px;
        }

        .num-active {
          background: #f6f8f9;
        }
      }

      img {
        width: 33px;
        height: 33px;
      }
    }

    .offline1 {
      display: flex;
      align-items: center;
      color: #ffffff;
      background-color: #ffffff;
      font-size: 12px;

      div:first-child {
        background-color: #08243c;
        border-radius: 4px;
        width: 86px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        margin-left: 25%;
      }

      // div:last-child {
      // 	background-color: #E66617;
      // 	border-radius: 4px;
      // 	width: 86px;
      // 	height: 30px;
      // 	text-align: center;
      // 	line-height: 30px;
      // 	margin-left: 15px;
      // }
    }

    .offlin2 {
      background-color: #ffffff;
      font-size: 12px;
      color: #333333;
      padding: 35px 11px 17px 11px;
      line-height: 25px;
    }
  }
}
</style>
